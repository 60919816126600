import type {Company} from '$types/company';
import type {ApiClient} from '@blg/jsapilib/browser';
import type {
	MailSmtpAccountApiObject,
	CmsWebsiteContactApiObject,
	UserUserApiObject,
	SecurityUserGroupApiObject
} from '@blg/api-types';
import uniq from 'lodash/uniq';
import {get, type Writable, writable} from 'svelte/store';

export type LegalEntities = {
	ids: number[];
};

export type UserGroup<T extends boolean = false> =
	SecurityUserGroupApiObject<T>;
export type User<T extends boolean = false> = UserUserApiObject<T>;
export type SmtpAccount<T extends boolean = false> =
	MailSmtpAccountApiObject<T>;
export type CmsContact<T extends boolean = false> =
	CmsWebsiteContactApiObject<T>;

export type UserInfos = {
	user: User | null;
	apiClient: ApiClient | null;
	selectedCompany?: Company | null;
	lastUpdate: number | null;
};

interface UserStore extends Writable<UserInfos> {
	setUser: (
		apiClient: UserInfos['apiClient'],
		user: UserInfos['user']
	) => void;
	getCompaniesIds: () => Company['id'][];
}

export const USER_DEFAULT: UserInfos = {
	user: null,
	lastUpdate: null,
	apiClient: null,
	selectedCompany: null
};

function getLocalStorageCompany(): Company | null {
	const company: string | null = localStorage.getItem('selectedCompany');
	return company ? (JSON.parse(company) as Company) : null;
}

function createUserStore(): UserStore {
	const userStore = writable(USER_DEFAULT);

	return {
		...userStore,
		setUser: (apiClient, user) => {
			const selectedCompany = getLocalStorageCompany();

			userStore.set({
				apiClient,
				user,
				lastUpdate: Date.now(),
				selectedCompany
			});
		},
		getCompaniesIds: () => {
			const user = getStoreUser();
			const companyId = user?.company?.id;

			const rightCompaniesIds =
				user?.rightCompanies.map((c) => c.company.id) || [];

			return companyId
				? uniq([companyId, ...rightCompaniesIds])
				: rightCompaniesIds;
		}
	};
}

export function getApiClient() {
	return get(userStore).apiClient;
}

export function getStoreUser() {
	return get(userStore).user;
}

export const userStore = createUserStore();
