import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/(app)/(dashboard)": [6,[2,4],[3]],
		"/(app)/account": [21,[2],[3]],
		"/(app)/(listing)/alert": [7,[2,5],[3]],
		"/(app)/cms-page/[name]": [22,[2],[3]],
		"/(app)/(listing)/collaborator": [8,[2,5],[3]],
		"/(app)/(listing)/contract": [9,[2,5],[3]],
		"/(app)/(listing)/contract/validation/[id=sqlId]": [11,[2,5],[3]],
		"/(app)/(listing)/contract/[id=sqlId]": [10,[2,5],[3]],
		"/(app)/datadog": [23,[2],[3]],
		"/(app)/equipment/[slug=both]": [24,[2],[3]],
		"/(app)/(listing)/fleet": [12,[2,5],[3]],
		"/(app)/fleet/[id=sqlId]": [25,[2],[3]],
		"/(app)/(listing)/invoice": [13,[2,5],[3]],
		"/(app)/(listing)/invoice/[id=sqlId]": [14,[2,5],[3]],
		"/(app)/login": [26,[2],[3]],
		"/(app)/(listing)/message": [15,[2,5],[3]],
		"/(app)/message/[id=sqlId]": [27,[2],[3]],
		"/(app)/register": [28,[2],[3]],
		"/(app)/(listing)/rental_fleet": [16,[2,5],[3]],
		"/(app)/(listing)/sale": [17,[2,5],[3]],
		"/(app)/(listing)/sale/[id=sqlId]": [18,[2,5],[3]],
		"/(app)/(listing)/service": [19,[2,5],[3]],
		"/(app)/(listing)/service/[id=sqlId]": [20,[2,5],[3]],
		"/(app)/wk/[wk]": [29,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';